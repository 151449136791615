import {destroyCookie, parseCookies, setCookie} from 'nookies';
import {REFRESH_TOKEN_COOKIE, SESSION_TYPE, STORAGE_ACCESS_TOKEN} from "../core/constants";

export const COOKIE_EXPIRATION_TIME = 60 * 60 * 24 // 1 day

type CreateSessionCookies = {
    token?: string
}
export const createSession = (params: CreateSessionCookies) => {
    const {token} = params;

    if (token) {
        const sessionType = localStorage.getItem(SESSION_TYPE);
        if (sessionType == "local") {
            localStorage.setItem(STORAGE_ACCESS_TOKEN, token);
        } else {
            sessionStorage.setItem(STORAGE_ACCESS_TOKEN, token);
        }
    }
}

export const removeSession = () => {
    localStorage.clear();
    sessionStorage.clear();
    destroyCookie(null, REFRESH_TOKEN_COOKIE);
}

export const getAccessToken = () => {
    let token = localStorage.getItem(STORAGE_ACCESS_TOKEN) ||
        sessionStorage.getItem(STORAGE_ACCESS_TOKEN);
    if (!token) {
        token = ""
    }
    return token;
}

export const getRefreshToken = () => {
    const cookies = parseCookies();
    return cookies[REFRESH_TOKEN_COOKIE];
}