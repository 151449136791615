import {IUser, SignInCredentials} from "../types/IUser";
import {AxiosError} from "axios";
import {createContext} from "react";

export type AuthContextData = {
  user: IUser
  isAuth: boolean
  loadingUserData: boolean
  signIn: (credentials: SignInCredentials) => Promise<void | AxiosError>
  signOut: () => void
}

const AuthContext = createContext({} as AuthContextData)

export default AuthContext;