import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ACCESS_LEVEL_ADMIN,
  AUDIT_PAGE_LINK,
  COMPANIES_PAGE_LINK,
  CONTRACT_PAGE_LINK,
  GENERATE_LICENSE_PAGE_LINK,
  MAIN_PAGE_LINK,
  SOFTWARE_PAGE_LINK,
  USERS_PAGE_LINK
} from "../../core/constants";
import AuthContext from "../../context/AuthContext";
import GearWideIcon from "../Icons/GearWideIcon";
import BuildingsIcon from "../Icons/BuildingsIcon";

import cn from "classnames";
import CloudUploadFillIcon from "../Icons/CloudUploadFillIcon";
import PeopleIcon from "../Icons/PeopleIcon";
import BookHalfIcon from "../Icons/BookHalfIcon";

const Sidebar = () => {
  const contextValue = useContext(AuthContext);
  const user = contextValue?.user;
  const role = user.role.access_level;

  const [currentPage, setCurrentPage] = useState("/");

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <div className="bg-primary" style={{ maxWidth: "250px" }}>
      <div
        className="d-flex flex-column align-items-center align-items-sm-start px-2 pt-2 text-white"
        style={{ minHeight: "calc(100vh - 83px)" }}
      >

        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
          <li className="nav-item">
            <NavLink to={MAIN_PAGE_LINK} className={cn("nav-link px-0")}>
              <i
                className={cn("fs-4 bi-house align-middle",
                  currentPage === MAIN_PAGE_LINK
                    ? "text-secondary" : "text-white")}
              />
              <span
                className={cn("ms-1 d-none d-sm-inline align-middle",
                  currentPage === MAIN_PAGE_LINK
                    ? "text-secondary" : "text-white")}
              >
                Главная страница
              </span>
            </NavLink>
          </li>


          <li className="nav-item">
            <NavLink to={COMPANIES_PAGE_LINK} className="nav-link px-0">
              <BuildingsIcon
                className={cn("align-middle",
                  currentPage.startsWith(COMPANIES_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              />
              <span
                className={cn("ms-1 d-none d-sm-inline align-middle",
                  currentPage.startsWith(COMPANIES_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              >
                Компании
              </span>
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink to={CONTRACT_PAGE_LINK} className="nav-link px-0">
              <BookHalfIcon
                className={cn("align-middle",
                  currentPage.startsWith(CONTRACT_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              />
              <span
                className={cn("ms-1 d-none d-sm-inline align-middle",
                  currentPage.startsWith(CONTRACT_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              >
                Контракты
              </span>
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink to={USERS_PAGE_LINK} className="nav-link px-0">
              <PeopleIcon
                className={cn("align-middle",
                  currentPage.startsWith(USERS_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              />
              <span
                className={cn("ms-1 d-none d-sm-inline align-middle",
                  currentPage.startsWith(USERS_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              >
                Пользователи
              </span>
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink to={SOFTWARE_PAGE_LINK} className="nav-link px-0">
              <CloudUploadFillIcon
                className={cn("align-middle",
                  currentPage.startsWith(SOFTWARE_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              />
              <span
                className={cn("ms-1 d-none d-sm-inline align-middle",
                  currentPage.startsWith(SOFTWARE_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              >
                 Загруженное ПО
              </span>
            </NavLink>
          </li>

          {role && role >= ACCESS_LEVEL_ADMIN && (
            <>
              <li className="nav-item">
                <NavLink to={GENERATE_LICENSE_PAGE_LINK} className="nav-link px-0">
                  <GearWideIcon
                    className={cn("align-middle",
                      currentPage === GENERATE_LICENSE_PAGE_LINK
                        ? "text-secondary" : "text-white")}
                  />
                  <span
                    className={cn("ms-1 d-none d-sm-inline align-middle",
                      currentPage === GENERATE_LICENSE_PAGE_LINK
                        ? "text-secondary" : "text-white")}
                  >
                Сгенерировать лицензию
              </span>
                </NavLink>
              </li>


              <li className="nav-item">
                <NavLink to={AUDIT_PAGE_LINK} className="nav-link px-0">
                  <i className={cn("fs-4 bi bi-file-earmark-ruled align-middle",
                      currentPage === AUDIT_PAGE_LINK
                        ? "text-secondary" : "text-white")}
                  />
                  <span
                    className={cn("ms-1 d-none d-sm-inline align-middle",
                      currentPage === AUDIT_PAGE_LINK
                        ? "text-secondary" : "text-white")}
                  >
                Аудит
              </span>
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
