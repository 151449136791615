import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { userService } from '../../services/userService';
import { ISoftwareApplication, softwareApplicationInitialState } from '../../types/ISoftware';

interface ProgramSelectProps {
  isLoadingMode: boolean;
  softAppID: number;
  setSoftAppID: Dispatch<SetStateAction<number>>;
}


const ProgramSelect: FC<ProgramSelectProps> = ({
                                                 isLoadingMode,
                                                 softAppID,
                                                 setSoftAppID
                                               }) => {
  const [softwareApplication, setSoftwareApplication] =
    useState<ISoftwareApplication[]>([softwareApplicationInitialState]);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSoftAppID(Number(e.target.value));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userService.getSoftwareApplication();
        setSoftwareApplication(response);
      } catch (e) {
      }
    };

    fetchData();
  }, []);

  return (
    <div className="col-12">
      <label htmlFor="program_id">Программа</label>
      <div className="input-group">
        {isLoadingMode && (
          <div className="input-group-text">
            <i className="bi bi-person-fill"></i>
          </div>
        )}
        <select
          id="program_id"
          className="form-select"
          name="program_id"
          onChange={handleChange}
        >
          {!isLoadingMode && (
            <option value={0}>Все программы</option>
          )}
          {softwareApplication.map((item: ISoftwareApplication, key: number) => (
            <option key={key} value={item.ID}>{item.name}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default ProgramSelect;