export const getDate = (date: string) => {
  return new Date(date).toLocaleDateString();
};

export const getDateWithTime = (dateStr: string) => {

  const date = new Date(dateStr);


  const months = [
    "января", "февраля", "марта",
    "апреля", "мая", "июня",
    "июля", "августа", "сентября",
    "октября", "ноября", "декабря"
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const monthName = months[monthIndex];

  return `${day} ${monthName} ${year}, ${hours}:${minutes}`;
};


export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "long", year: "numeric" };
  const final = new Intl.DateTimeFormat("ru-RU", options).format(date);
  return final;
};

export const formatDateTime = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false
  };
  return new Intl.DateTimeFormat("ru-RU", options).format(date);
};


export const formatDateSimple = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};