import { ChangeEvent, Dispatch, FC, useId } from 'react';

interface DescriptionInputProps {
  description: string;
  setDescription: Dispatch<string>
}


const DescriptionInput: FC<DescriptionInputProps> = ({ description, setDescription }) => {
  const id = useId();
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  return (
    <div className="col-12">
      <label>Описание версии</label>
      <div className="form-floating">
        <textarea
          className="form-control"
          placeholder="Leave a comment here"
          id={`description-${id}`}
          value={description}
          onChange={handleChange}
        />
        <label className="ms-2" htmlFor={`description-${0}`}>
          Введите текст
        </label>
      </div>
    </div>);
};

export default DescriptionInput;