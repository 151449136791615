import { FC } from "react";

// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import AuthProvider from "./providers/AuthProvider";
import Router from "./routes/Router";
import ThemeProvider from "./providers/ThemeProvider";
import ToastProvider from "./providers/ToastProvider";


const App: FC = () => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <ToastProvider>
          <Router />
        </ToastProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
