import { ChangeEvent, Dispatch, FC, useId } from 'react';

interface ChangeLogInputProps {
  changeLog: string;
  setChangeLog: Dispatch<string>;
}


const ChangeLogInput: FC<ChangeLogInputProps> = ({ changeLog, setChangeLog }) => {
  const id = useId();
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setChangeLog(e.target.value);
  };

  return (
    <div className="col-12">
      <label>Список изменений</label>
      <div className="form-floating">
        <textarea
          className="form-control"
          placeholder="Leave a comment here"
          id={`changeLog-${id}`}
          value={changeLog}
          onChange={handleChange}
        />
        <label className="ms-2" htmlFor={`description-${0}`}>
          Введите текст
        </label>
      </div>
    </div>
  );
};

export default ChangeLogInput;