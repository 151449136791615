import { ReactNode, useEffect, useState } from "react";
import { SignInCredentials, userInitialState } from "../types/IUser";
import { useLocation, useNavigate } from "react-router-dom";
import { createSession, getAccessToken, removeSession } from "../utils/tokens";
import { userService } from "../services/userService";
import { setAuthHeader } from "../core/interceptor";
import { SIGN_IN_PAGE_LINK } from "../core/constants";
import AuthContext from "../context/AuthContext";
import { api, apiDefault } from "../core/axios";
import useStorage from "../hooks/useStorage";

type Props = {
  children: ReactNode;
};

const AuthProvider = (props: Props) => {
  const { children } = props;

  const [user, setUser] = useStorage("user", userInitialState);
  const [loadingUserData, setLoadingUserData] = useState<boolean>(true);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const token = getAccessToken();
  const isAuth = Boolean(token);

  const signIn = async (signInData: SignInCredentials) => {
    const { email, password } = signInData;

    try {
      const response = await apiDefault.post("/auth/sign-in", {
        email,
        password,
      });
      const { AccessToken } = response.data;
      createSession({ token: AccessToken });

      const user = await userService.getProfile();
      setUser(user.data);
      setAuthHeader({ request: api.defaults, token });
    } catch (e: any) {
      if (e.response.status >= 400) {
        return e.response.data.message;
      }
    }
  };

  const signOut = () => {
    setUser(userInitialState);
    removeSession();
    setLoadingUserData(false);
    navigate(SIGN_IN_PAGE_LINK);
  };

  useEffect(() => {
    if (!token) {
      setUser(userInitialState);
      removeSession();
      setLoadingUserData(false);
    }
  }, [navigate, pathname, token]);

  useEffect(() => {
    const token = getAccessToken();

    const getUserData = async () => {
      setLoadingUserData(true);

      try {
        const response = await api.get(`/api/users/profile`);

        if (response?.data) {
          setUser(response.data);
        }
      } catch (e) {
        // TODO handler errors
      } finally {
        setLoadingUserData(false);
      }
    };

    if (token) {
      setAuthHeader({ request: api.defaults, token });
    }

    if (!user) {
      getUserData();
    }

    setLoadingUserData(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        user,
        loadingUserData,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
