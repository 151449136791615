import { FC } from 'react';

import cn from 'classnames';

interface StatWidgetWithPercentProps {
  title: string;
  titleType?: string;
  value: string | number;
}

const StatWidgetWithPercent: FC<StatWidgetWithPercentProps> = ({title, titleType, value}) => {
  return (
    <div className="col-md-6 col-xl-3 mb-4">
      <div className="card border-start-info py-2">
        <div className="card-body">
          <div className="row align-items-center no-gutters">
            <div className="col me-2">
              <div className={cn('text-uppercase fw-bold text-xs mb-1',
                titleType ? titleType : 'text-primary')}>
                <span>{title}</span>
              </div>
              <div className="row g-0 align-items-center">
                <div className="col-auto">
                <div className="fw-bold h5 mb-0 me-3">
                    <span>{value}%</span>
                  </div>
                </div>
                <div className="col">
                  <div className="progress progress-sm">
                    <div
                      className="progress-bar bg-info"
                      style={{ width: `${value}%` }}
                    >
                      <span className="visually-hidden">50%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatWidgetWithPercent;