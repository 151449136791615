// users page links
export const MAIN_PAGE_LINK = "/";
export const COMPANY_PAGE_LINK = "/company";
export const LICENSE_PAGE_LINK = "/licenses";
export const DOWNLOAD_PAGE_LINK = "/downloads";


// auth page links
export const SIGN_IN_PAGE_LINK = "/sign-in";
export const FORGOT_PASSWORD_PAGE_LINK = "/forgot-password";
export const RESET_PASSWORD_PAGE_LINK = "/reset-password";
export const REGISTER_PAGE_LINK = "/register";


// common page links
export const PROFILE_PAGE_LINK = "/profile";


/* admin page links */
export const AUDIT_PAGE_LINK = "/audit";

// user pages
export const USERS_PAGE_LINK = "/users";
export const USERS_CREATE_PAGE_LINK = "/users/create";
export const USERS_BY_ID_PAGE_LINK = "/users/:id";
export const getUserInfoPageLinkByID = (id: number) => {
  return `/users/${id}`;
};

// company pages
export const COMPANIES_PAGE_LINK = "/company";
export const COMPANIES_PAGE_LINK_BY_ID = "/company/:id";
export const COMPANY_CONTRACTS_PAGE_LINK = "/company/:id/contracts"
export const getCompanyPageByID = (id: number) => {
  return `/company/${id}/contracts`;
};
export const COMPANIES_CREATE_PAGE_LINK = "/company/create";
export const COMPANY_CONTRACTS_PAGE_LINK23 = "/company/:companyID/contract/:contractID";
export const getCompanyContractsByCompanyAndContractID = (companyID: number, contractID: number) => {
  return `/company/${companyID}/contract/${contractID}`;
};

// contract pages
export const CONTRACT_PAGE_LINK = "/contracts";
export const CONTRACT_LICENSES_BY_CONTRACT_ID_PAGE_LINK = "/contracts/:id";
export const getContractLicensesPageLinkByContractID = (id: number) => {
  return `/contracts/${id}`;
};

export const CONTRACT_LICENSE_INFO_BY_NUMBER_PAGE_LINK = "/contracts/:id/licenses/:number";
export const getContractLicenseInfoPageLinkByContractNumber = (id: number, contractNumber: string) => {
  return `/contracts/${id}/licenses/${contractNumber}`;
};

export const CONTRACT_CREATE_PAGE_LINK = "/contracts/create";
export const CONTRACT_ORDER_CREATE_PAGE_LINK = "/contracts/create/order";


export const GENERATE_LICENSE_PAGE_LINK = "/generate-license";
export const SOFTWARE_PAGE_LINK = "/software";
export const SOFTWARE_CREATE_PAGE_LINK = "/software/create";


export const SERVER_URL = process.env.NODE_ENV === "production" ? "https://support.ineum.ru" : "http://localhost:8080";
export const ADMIN_SERVER_URL = process.env.NODE_ENV === "production" ? "https://support.ineum.ru" : "http://localhost:8081";


// auth const
export const SESSION_TYPE = "session_type";
export const STORAGE_ACCESS_TOKEN = "token";
export const REFRESH_TOKEN_COOKIE = "refreshToken";


// access level const
export const ACCESS_LEVEL_USER = 1;
export const ACCESS_LEVEL_MODERATOR = 2;
export const ACCESS_LEVEL_ADMIN = 3;
export const ACCESS_LEVEL_CHIEF_ADMIN = 4;


export const FILE_TYPE_CCB = "ccb";
export const FILE_TYPE_ZIP = "zip";

export const STATUS_CREATED = 201;
export const HTTP_MESSAGE_ACCOUNT_BLOCKED = "аккаунт заблокирован";

