export interface ISoftware {
  ID: number;
  name: string;
  description: string;
}

export const softwareInitialState: ISoftware = {
  ID: 0,
  name: '',
  description: ''
};


export interface ISoftwareApplication {
  ID: number;
  softwareID: number;
  name: string;
  description: string;
}

export const softwareApplicationInitialState: ISoftwareApplication = {
  ID: 0,
  softwareID: 0,
  name: '',
  description: ''
};


export interface ISoftwareVersionResponse {
  data: ISoftwareVersion[];
  count: number;
  lastPage: number;
  currentPage: number;
}

export interface ISoftwareVersion {
  ID: number;
  name: string;
  version: string;
  releaseDate: string;
  isActive: boolean;
  isFullRelease: boolean;
  description: string;
  operationSystemName: string;
  changeLog: string;
  fileName: string;
  fileSize: number;
  fileType: string;
  fileCrc32: number;
  downloadCount: number;
  createdAt: string;
}


export const softwareVersionInitialState: ISoftwareVersion = {
  ID: 0,
  name: '',
  version: '',
  releaseDate: '',
  isActive: true,
  isFullRelease: true,
  description: '',
  operationSystemName: '',
  changeLog: '',
  fileName: '',
  fileSize: 0,
  fileType: '',
  fileCrc32: 0,
  downloadCount: 0,
  createdAt: ''
};