import { FC } from 'react';
import useSession from '../../hooks/useSession';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import Row from '../../components/Wrapper/Row';
import Col from '../../components/Wrapper/Col/Col';

const ProfileInfo: FC = () => {
  const { user } = useSession();

  return (
    <Card>
      <CardHeader className="py-3">
        <p className="m-0">Данные пользователя</p>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="email">
                <strong>Email</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="email"
                autoComplete="off"
                placeholder="email"
                name="username"
                value={user?.email}
                disabled
              />
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="company">
                <strong>Компания</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="company"
                autoComplete="off"
                placeholder="Компания"
                name="company_name"
                value={user?.company_name}
                disabled
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="username">
                <strong>Имя</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="username"
                autoComplete="off"
                placeholder="Имя"
                name="username"
                value={user?.name}
                disabled
              />
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="surname">
                <strong>Фамилия</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="surname"
                placeholder="Фамилия"
                autoComplete="off"
                name="surname"
                value={user?.surname}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="role_name">
                <strong>Тип аккаунта</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="role_name"
                autoComplete="off"
                placeholder="Роль"
                name="role_name"
                value={user?.role.name}
                disabled
              />
            </div>
          </Col>
          <Col>
            <div></div>
          </Col>
        </Row>
      </CardBody>
    </Card>

  );
};

export default ProfileInfo;
