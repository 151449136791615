import { FC, useEffect, useState } from "react";
import { adminService } from "../../../services/adminService";
import Container from "../../../components/Wrapper/Container";
import CardHeader from "../../../components/Card/CardHeader";
import Row from "../../../components/Wrapper/Row";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import StatWidget from "../../../components/Widgets/StatWidget";
import StatWidgetWithPercent from "../../../components/Widgets/StatWidgetWithPercent";
import useToast from "../../../hooks/useToast";

const AdminPanelMainPage: FC = () => {
  const { addToast } = useToast();

  const [licensesIssuedPerDay, setLicensesIssuedPerDay] = useState(0);
  const [licensesIssuedPerMonth, setLicensesIssuedPerMonth] = useState(0);
  const [licensesIssuedPerYear, setLicensesIssuedPerYear] = useState(0);
  const [percentContractIssued, setPercentContractIssued] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getDashboard();
        setLicensesIssuedPerDay(
          response.data.LicenseStatistics.LicensesIssuedPerDay
        );
        setLicensesIssuedPerMonth(
          response.data.LicenseStatistics.LicensesIssuedPerMonth
        );
        setLicensesIssuedPerYear(
          response.data.LicenseStatistics.LicensesIssuedPerYear
        );
        setPercentContractIssued(
          response.data.LicenseStatistics.LicensesPercentageIssued
        );
      } catch (e) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };
    fetchData();
  }, []);

  return (
    <Card>
      <CardHeader>
        <h6 className="mt-2">Dashboard</h6>
      </CardHeader>
      <CardBody style={{ minHeight: "calc(100vh - 102px - 73px)" }}>
        <Container>
          <Row>
            <StatWidget
              title="Выдано лицензий (за год)"
              titleType="text-danger"
              value={licensesIssuedPerYear}
            />
            <StatWidget
              title="Выдано лицензий (за месяц)"
              titleType="text-warning"
              value={licensesIssuedPerMonth}
            />
            <StatWidget
              title="Выдано лицензий (за день день)"
              titleType="text-success"
              value={licensesIssuedPerDay}
            />

            <StatWidgetWithPercent
              title="Процент выданных лицензий от всех"
              titleType="text-info"
              value={percentContractIssued}
            />
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};
export default AdminPanelMainPage;
