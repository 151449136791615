import {ReactNode, Suspense} from "react";
import useSession from "../hooks/useSession";
import {Navigate} from "react-router-dom";
import {MAIN_PAGE_LINK} from "../core/constants";


type Props = {
    redirectTo?: string;
    permissions?: number[];
    children: ReactNode;
};

const PrivateRoute = (props: Props) => {
    const {redirectTo, permissions, children} = props;
    const {isAuth, user, loadingUserData} = useSession();

    if (loadingUserData) {
        return null;
    }

    if (!isAuth) {
        // @ts-ignore
        return <Navigate to={redirectTo}/>
    }

    if (permissions) {
        let hasPermission = false;
        let adminRole = user?.role.access_level;
        if (adminRole) {
            hasPermission = permissions.includes(adminRole);
        }

        if (!hasPermission) {
            return <Navigate to={MAIN_PAGE_LINK}/>;
        }
    }
    return (
        <Suspense>{children}</Suspense>
    );
}

export default PrivateRoute;