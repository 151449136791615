import api, { apiAdmin } from '../core/axios';
import { IRole } from '../types/IRole';

interface IRawRole {
  id: number;
  access_level: number;
  name: string;
}

type RawApiRoleResponse = IRawRole[];
type ApiRoleResponse = IRole[];

const transformRoles = (rawResponse: RawApiRoleResponse): ApiRoleResponse => {
  return rawResponse.map((item: IRawRole) => ({
    ID: item.id,
    accessLevel: item.access_level,
    name: item.name
  }));
};

export const roleService = {
  async getAll(): Promise<ApiRoleResponse> {
    const response = await apiAdmin.get<RawApiRoleResponse>('/api/role');

    return transformRoles(response.data);
  }
};
