import { FC } from 'react';

import cn from 'classnames';

interface StatWidgetProps {
  title: string;
  titleType?: string;
  value: string | number;
}

const StatWidget: FC<StatWidgetProps> = ({title, titleType, value}) => {
  return (
    <div className="col-md-6 col-xl-3 mb-4">
      <div className="card border-start-primary py-2">
        <div className="card-body">
          <div className="row align-items-center no-gutters">
            <div className="col me-2">
              <div className={cn("text-uppercase fw-bold text-xs mb-1",
                titleType ? titleType : "text-primary")}>
                <span>{title}</span>
              </div>
              <div className="fw-bold h5 mb-0">
                <span>{value}</span>
              </div>
            </div>
            <div className="col-auto">
              <i className="fas fa-calendar fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatWidget;